import {cl,constant,globs,secsToDisplayDate} from '../../components/utils/utils'
import {dbVals} from '../../components/utils/http';
import {getParamId,getParamId2,eqOverrides} from '../utils/utils'
import {lightUnit} from '../utils/utils'

// pi[1800].channels_configuration["zoneIndex"] = 1
// pi[1800].channels_configuration["unitIndex"] = 2
// pi[1800].channels_configuration["channelIndex"] = 3
// pi[1800].channels_configuration["unix_timestamp(modified)"] = 4
// pi[1800].channels_configuration["used"] = 5
// pi[1800].channels_configuration["isAnalog"] = 6
// pi[1800].channels_configuration["channelName"] = 7
// pi[1800].channels_configuration["channelType"] = 8
// pi[1800].channels_configuration["channelData"] = 9
// pi[1800].channels_configuration["timedEnabled"] = 10
// pi[1800].channels_configuration["timedInterval"] = 11
// pi[1800].channels_configuration["timedStartTime"] = 12
// pi[1800].channels_configuration["timedEndTime"] = 13
// pi[1800].channels_configuration["timedOutput"] = 14
// pi[1800].channels_configuration["stageDehumidfy1"] = 15
// pi[1800].channels_configuration["stageDehumidfy2"] = 16
// pi[1800].channels_configuration["stageColdDehumidfy"] = 17
// pi[1800].channels_configuration["stageHumidfy"] = 18
// pi[1800].channels_configuration["expansionFailSafeOutput"] = 19
// pi[1800].channels_configuration["userComment"] = 20

// pi[1800].snapshot_channels["snapshotId"] = 1
// pi[1800].snapshot_channels["channelIndex"] = 2
// pi[1800].snapshot_channels["channelData"] = 3
// pi[1800].snapshot_channels["position"] = 4
// pi[1800].snapshot_channels["relay"] = 5
// pi[1800].snapshot_channels["channelOverride"] = 6
// pi[1800].snapshot_channels["analogOutput"] = 7
// pi[1800].snapshot_channels["co2Setpoint"] = 8
// pi[1800].snapshot_channels["microzoneSetpoint"] = 9
// pi[1800].snapshot_channels["proportionalZoneLowSetpoint"] = 10
// pi[1800].snapshot_channels["proportionalZoneHighSetpoint"] = 11
// pi[1800].snapshot_channels["pumpPeristalticSetpoint"] = 12
// pi[1800].snapshot_channels["pumpPeristalticTankLevel"] = 13
// pi[1800].snapshot_channels["pumpPeristalticInjectedMilliliters"] = 14
// pi[1800].snapshot_channels["pumpPeristalticInjectedSeconds"] = 15
// pi[1800].snapshot_channels["lightDailyLightIntegral"] = 16
// pi[1800].snapshot_channels["auxOutputStatus"] = 17
// pi[1800].snapshot_channels["auxOutputValue"] = 18
// pi[1800].snapshot_channels["auxEquipmentStatus"] = 19
// pi[1800].snapshot_channels["auxEquipmentValue"] = 20

// if (eqOverride != EquipmentOverride.OVRTYPE_NONE ||
// channelSnapshotData["inWindow"] == null || !bool.TryParse((string)channelSnapshotData["inWindow"], out inWindow) ||
// channelSnapshotData["inQueue"] == null || !bool.TryParse((string)channelSnapshotData["inQueue"], out inQueue) ||
// channelSnapshotData["activeCount"] == null || !int.TryParse((string)channelSnapshotData["activeCount"], out activeCount) ||
// channelSnapshotData["offTime"] == null || !int.TryParse((string)channelSnapshotData["offTime"], out offTime) ||
// channelSnapshotData["accumulatedLight"] == null || !double.TryParse((string)channelSnapshotData["accumulatedLight"], out accumulatedLight) ||
// channelData["soil_moisture_threshold"] == null || !double.TryParse((string)channelData["soil_moisture_threshold"], out soilThreshold) ||
// channelData["on_duration"] == null || !int.TryParse((string)channelData["on_duration"], out onDuration) ||
// channelData["off_duration"] == null || !int.TryParse((string)channelData["off_duration"], out offDuration) ||
// channelData["soil_moisture_input"] == null || !int.TryParse((string)channelData["soil_moisture_input"], out soilInputSensor) ||
// channelData["irrigation_mode"] == null || !int.TryParse((string)channelData["irrigation_mode"], out irrMode) || !IrrigationEquipmentMode.IntValues.ContainsKey(irrMode)
// )
// {

//   if (mode == IrrigationEquipmentMode.IRRMODE_SCHD)
//   {
//       TimeSpan? nextTime = GetNextIrrigationTime(channelData, channelSnapshotData, (snapshot.UnitTime ?? DateTime.Now).TimeOfDay);
//       if (nextTime.HasValue)
//       {
//           status = string.Format("{0} {1:hh:mm tt}", activeCount, DateTime.Today.Add(nextTime.Value));
//       }
//       else
//       {
//           status = string.Format("{0} Done", activeCount);
//       }
//   }

var getNextIrrigationTime=(ci,ch,ccd)=>{
  let now=Math.floor((ci.unitTime % 86400) / 60)
  // cl(now)
//   cl(ci)
//   cl(ch)
//   cl(ccd)
//   cl(now)
  let times=[]
  for(let i=0;i<6;i++){
    let start=+ch[getParamId(ccd,`start_time_${i+1}`)]
    if(start<1440){times.push(start)}
//     cl(start)
  }
  // cl(times)
  if(times.length){
    times.push(1440+times[0])
    for(let i=0;i<times.length;i++){
//       cl(times[i],now,times[i]>now)
      if(+times[i]>now){ return times[i] }
    }
  }else{
//     cl("returning blank")
    return ""
  }
//   cl(times)
}

var gesIrr=(ci)=>{
  let ch=ci.ch
  let scd=ci.scd
  let ccd=ci.ccd
//   cl(scd)
  let inWindow=+ch[getParamId(scd,"inWindow")]
  let inQueue=+ch[getParamId(scd,"inQueue")]
  let activeCount=+ch[getParamId(scd,"activeCount")]||0
  let offTime=+ch[getParamId(scd,"offTime")]
  let accumulatedLight=+ch[getParamId(scd,"accumulatedLight")]
  let soil_moisture_threshold=+ch[getParamId(ccd,"soil_moisture_threshold")]
  let on_duration=+ch[getParamId(ccd,"on_duration")]
  let off_duration=+ch[getParamId(ccd,"off_duration")]
  let soil_moisture_input=+ch[getParamId(ccd,"soil_moisture_input")]
  let irrigation_mode=+ch[getParamId(ccd,"irrigation_mode")]
//   cl(irrigation_mode)
  switch(irrigation_mode){
    case constant.IRR_SCHEDULED:
/* show the number of times it's been on today - active count,
if on, show on, otherwise show next time*/
      if(ci.chOverride==0){
        if(ci.chRelay){
          return `${activeCount} On`
        }else{
          let next=60*getNextIrrigationTime(ci,ch,ccd)
//           cl(next)
          let da=secsToDisplayDate(next,"h:mm AP")
          if(next > 86400){
            return `${activeCount}, Done, Next: ${da} Tomorrow`
          }else{
            return `${activeCount}, Next: ${da}`
          }
        }
      }else{
        return `${(ci.chRelay)?"On":"Off"}, ${ci.chOverText}`
      }
      break
    case constant.IRR_ACCUMULATED:
      if(ci.chOverride==0){
        return `${(ci.chRelay)?"On":"Off"}`
      }else{
        return `${(ci.chRelay)?"On":"Off"}, ${eqOverrides[ci.chOverride]}`
      }
      cl(ci)
      break
    case constant.IRR_CYCLE:
//       cl(offTime)
//       cl(off_duration)
      if(ci.chOverride==0){
        if(ci.chRelay){
          return `${activeCount} On`
        }else{
          let dur=60*off_duration-offTime// in minutes
          // cl(dur, off_duration,offTime,getParamId(scd,"offTime"))
          let da=secsToDisplayDate(dur,"hh:mm:ss")
          return `${activeCount} Off, ${da} to next cycle`
        }
      }else{
        return `${(ci.chRelay)?"On":"Off"}, ${eqOverrides[ci.chOverride]}`
      }
      break
//   TimeSpan left = TimeSpan.FromSeconds(offDuration) - TimeSpan.FromSeconds(offTime);
//   status = string.Format(@"{0} {1:hh}h {1:mm}m {1:ss}s", activeCount, left);
    case constant.IRR_TRIGGER:
    case constant.IRR_SOIL_TRIGGER:
    case constant.IRR_VPD:
      break
  }
//   cl(accumulatedLight)
}

// bCond = (eqOverride != EquipmentOverride.OVRTYPE_NONE || channelSnapshotValue.Value < -1 || channelSnapshotValue > 1 ||
//     channelData["pump_type"] == null || !int.TryParse((string)channelData["pump_type"], out pumpType) || pumpType == 0 ||
//     channelData["probeSensorIndex"] == null || !int.TryParse((string)channelData["probeSensorIndex"], out probeIndex) || probeIndex < 0 || probeIndex >= 8 || !snapshotEcphs.ContainsKey(probeIndex) || !ecphConfigs.Any(e => e.EcphIndex == probeIndex) ||
//     channelData["feedingMode"] == null || !int.TryParse((string)channelData["feedingMode"], out feedingMode) ||
//     !snapshotChannel.PumpPeristalticSetpoint.HasValue ||
//     channelSnapshotData["peristalticState"] == null || !int.TryParse((string)channelSnapshotData["peristalticState"], out peristalticState) ||
//     peristalticState < 0 || peristalticState >= states.Length);

// else if (probeType == ProbeType.PROBE_TYPE_EC)
// {
//     var sensor = ecphConfig.Sensors.SingleOrDefault(e => e.SensorIndex == 0);
//     if (pumpType != 2)
//     {
//         ecphSetpoint = string.Format(", EC SETPOINT: {0:F" + nutrientPlaces + "}{1}", snapshotChannel.PumpPeristalticSetpoint.Value, units);
//     }
//     if (sensor == null || sensor.EcMapping == 0 || !ecphSnapshot.EC1.HasValue)
//     {
//         ecphStatus = string.Format(", EC READING: --{0}", units);
//     }
//     else
//     {
//         ecphStatus = string.Format(", EC READING: {1:F" + nutrientPlaces + "}{0}", units, ecphSnapshot.EC1.Value);
//     }
//     for (i = 1; i < 3; i++)
//     {
//         sensor = ecphConfig.Sensors.SingleOrDefault(e => e.SensorIndex == i);
//         if (sensor == null || sensor.EcMapping == 0)
//         {
//             continue;
//         }
//         else
//         {
//             if (altReading.Length == 0)
//             {
//                 altReading = ", ALTERNATE READINGS: ";
//             }
//             else
//             {
//                 altReading += " ";
//             }
// 
//             var value = ecs[i](ecphSnapshot);
//             if (value.HasValue)
//             {
//                 altReading += value.Value.ToString("F0") + units;
//             }
//             else
//             {
//                 altReading += "--" + units;
//             }
//         }
//     }
// }

var getECpHInfo=(ci)=>{// assume channel is a pump for now: "probeSensorIndex": 
//   cl(ci)// ci.ind is channel
//   cl(ci.ind)
  let se="snapshot_ecphs"
  let tank=+(ci.ch[getParamId(ci.ccd,"probeSensorIndex")]||0)
//   cl(getParamId(ci.ccd,"probeSensorIndex"))
//   cl(ci.ind,tank)
//   let curEC=(dbVals.z[ci.zInd][+tank+192][getParamId(se,"ec1")]||0)
  let curEC=getDBV(ci.zInd,+tank+192,getParamId(se,"ec1"))
  return {curEC:curEC}
}


var gesPump=(ci)=>{
  let ei=getECpHInfo(ci)
  let ch=ci.ch
  let sc=ci.sc
  let scd=ci.scd
  let ccd=ci.ccd
  let pump_type=+ch[getParamId(ccd,"pump_type")]
  let probeSensorIndex=+ch[getParamId(ccd,"probeSensorIndex")]
  let feedingMode=+ch[getParamId(ccd,"feedingMode")]
  let peristalticState=+ch[getParamId(scd,"peristalticState")]
  let pumpPeristalticSetpoint=+ch[getParamId(sc,"pumpPeristalticSetpoint")]
  let pumpPeristalticTankLevel=+ch[getParamId(sc,"pumpPeristalticTankLevel")]
  let pumpPeristalticInjectedMilliliters=+ch[getParamId(sc,"pumpPeristalticInjectedMilliliters")]
  let pumpPeristalticInjectedSeconds=+ch[getParamId(sc,"pumpPeristalticInjectedSeconds")]
  let curEC=ei.curEC
//   let pumpEC=
//   cl(pumpPeristalticTankLevel)// in liters
  
  switch(pump_type){
    case constant.PUMP_SUPPLY:
      break
    case constant.PUMP_PERISTALTIC_RECIRCULATING:
      break
    case constant.PUMP_PERISTALTIC_BATCH:
      if(ci.chOverride==0){
        if(ci.chRelay){
          return `CURRENTLY: On`
        }else{
          let tl=pumpPeristalticTankLevel / 3.785
          return `CURRENTLY: Off, ${(feedingMode)?"":`EC READING: ${curEC} uS,`}  TANK LEVEL: ${tl.toFixed(1)} Gal`
        }
      }else{
        return `${(ci.chRelay)?"On":"Off"}, ${eqOverrides[ci.chOverride]}`
      }
      break
      
    case constant.PUMP_PERISTALTIC_BALANCED:
      break
    default:
      break
  }
  
  if(ci.chOverride){
    return `${(ci.chRelay)?"On":"Off"}, ${eqOverrides[ci.chOverride]}`
  }else{
    return `${(ci.chRelay)?"On":"Off"}`
  }
}


var gesFValve=(ci)=>{
  let ch=ci.ch
  let ccd=ci.ccd
  let tankSensor=+ch[getParamId(ccd,"CD_tankSensor_fval")]
  if(ci.chOverride){
    return `${(ci.chRelay)?"On":"Off"}, ${eqOverrides[ci.chOverride]}`
  }else{
    return `${(ci.chRelay)?"On":"Off"}`
  }
  
//   if(ci.chOverride==0){
//     if(ci.chRelay){
//       return `CURRENTLY: On`
//     }else{
//       let tl=pumpPeristalticTankLevel / 3.785
//       return `CURRENTLY: Off, ${(feedingMode)?"":"EC READING: -- CF,"}  TANK LEVEL: ${tl} Gal`
//     }
//   }else{
//   }
//   return "pump"
}

// int sensor;
// if (channelData["vps_sensor"] != null && int.TryParse((string)channelData["vps_sensor"], out sensor) && 0 <= sensor && sensor < 5)
// {
//     var v = ventPositions[sensor](snapshot);
//     if (!v.HasValue)
//     {
//         status = string.Format("Open {0}%\nSensor Error\n{1}", channelSnapshotValue.Value, eqOverride.OverrideText);
//     }
//     else
//     {
//         status = string.Format("Open {0}%\nSensor {2}%\n{1}", channelSnapshotValue.Value, eqOverride.OverrideText, v.Value);
//     }
// }
// else
// {
//     status = string.Format("Open {0}%\n{1}", channelSnapshotValue.Value, eqOverride.OverrideText);
// }

var gesVent=(ci)=>{
  let ch=ci.ch
  let ccd=ci.ccd
  let vps_sensor=+ch[getParamId(ccd,"vps_sensor")]
  if(ci.chOverride==0){
    return `Open ${ci.chPos}%`
  }else{
    return `Open ${ci.chPos}%, ${eqOverrides[ci.chOverride]}`
  }
}

var gesCurtain=(ci)=>{
//   cl(ci)
//   cl(eqOverrides)
  let ch=ci.ch
  let ccd=ci.ccd
  let vps_sensor=+ch[getParamId(ccd,"vps_sensor")]
  if(ci.chOverride==0){
    return `Covered ${ci.chPos}%`
  }else{
    return `Covered ${ci.chPos}%, ${eqOverrides[ci.chOverride]}`
  }
}

// else if (type == EquipmentType.EQ_MZONE)
// {
//     if (eqOverride == EquipmentOverride.OVRTYPE_NONE)
//     {
//         int sensor;
//         string sensorStatus;
//         if (channelData["sensor"] != null && int.TryParse((string)channelData["sensor"], out sensor))
//         {
//             if (0 <= sensor && sensor < 5)
//             {
//                 var v = analogTemperatures[sensor](snapshot);
//                 if (!v.HasValue)
//                 {
//                     sensorStatus = "No Sensor";
//                 }
//                 else
//                 {
//                     if (v.Value > 215.0m)
//                     {
//                         sensorStatus = "Sensor Error";
//                     }
//                     else
//                     {
//                         sensorStatus = string.Format("{0:F2}{1}", v.Value, temperatureUnits == null ? null : temperatureUnits.DisplayText);
//                     }
//                 }
//             }
//             else
//             {
//                 sensorStatus = "No Sensor";
//             }
//         }
//         else
//         {
//             sensorStatus = "No Sensor";
//         }
//         status = string.Format("{0} {1}", isOn ? "ON" : "OFF", sensorStatus);
//     }
//     else
//     {
//         status = string.Format("{0} {1}", isOn ? "ON" : "OFF", eqOverride.OverrideText);
//     }
// }

var gesMZone=(ci)=>{
//   cl(ci)
  let ch=ci.ch
  let ccd=ci.ccd
//   cl(getParamId(ccd,"sensor_mzone"))
  let sensor=+ch[getParamId(ccd,"sensor_mzone")]// 0-4 analog temp
//   let temp=dbVals.z[ci.zInd][240][getParamId(ci.sn,`analogTemperature${sensor+1}`)]
  let temp=getDBV(ci.zInd,240,getParamId(ci.sn,`analogTemperature${sensor+1}`))
//   cl(sensor,temp)
  if(ci.chOverride==0){
    return `${(ci.chRelay)?"On":"Off"}, ${temp} \u00B0F`
  }else{
    return `${(ci.chRelay)?"On":"Off"}, ${eqOverrides[ci.chOverride]}`
  }
}


var gesMixV=(ci)=>{
//   cl(ci)
  let ch=ci.ch
  let ccd=ci.ccd
//   cl(getParamId(ccd,"sensor_mzone"))
  let sensor=+ch[getParamId(ccd,"air_temp_sensor")]// 0-4 analog temp
//   let temp=dbVals.z[ci.zInd][240][getParamId(ci.sn,`analogTemperature${sensor+1}`)]
  let temp=getDBV(ci.zInd,240,getParamId(ci.sn,`analogTemperature${sensor+1}`))
//   cl(sensor,temp)
  if(ci.chOverride==0){
    return `${ci.chPos}%, ${temp} \u00B0F ${eqOverrides[ci.chOverride]}`
  }else{
    return `${ci.chPos}%, ${temp} \u00B0F ${eqOverrides[ci.chOverride]}`
  }
}

var gesAnMixV=(ci)=>{
//   cl(ci)
  let ch=ci.ch
  let ccd=ci.ccd
//   cl(getParamId(ccd,"sensor_mzone"))
  let sensor=+ch[getParamId(ccd,"air_temp_sensor")]// 0-4 analog temp
//   let temp=dbVals.z[ci.zInd][240][getParamId(ci.sn,`analogTemperature${sensor+1}`)]
  let temp=getDBV(ci.zInd,240,getParamId(ci.sn,`analogTemperature${sensor+1}`))
  let anOut=(ci.chAnOut)?", "+ci.chAnOut.toPrecision(2)+"V":""
//   cl(sensor,temp)
  if(ci.chOverride==0){
    return `${ci.chPos}%, ${temp} \u00B0F ${eqOverrides[ci.chOverride]}${anOut}`
  }else{
    return `${ci.chPos}%, ${temp} \u00B0F ${eqOverrides[ci.chOverride]}${anOut}`
  }
}


var gesGenPid=(ci)=>{
//   cl(ci)
  let ch=ci.ch
  if(ci.chOverride==0){
    return `${ci.chPos}%`
  }else{
    return `${ci.chPos}% ${eqOverrides[ci.chOverride]}`
  }
}

var gesVarOut=(ci)=>{
//   cl(ci)
  let ch=ci.ch
  let ccd=ci.ccd
//   cl(getParamId(ccd,"sensor_mzone"))
//   cl("---------------------------------------------------------")
//   cl(getParamId(ccd,"CD_analog_min_varout"))
//   let anMin=ch[getParamId(ccd,"CD_analog_min_varout")]// 0-4 analog temp
//   let anMax=ch[getParamId(ccd,"CD_analog_max_varout")]// 0-4 analog temp
//   cl([ci.chPos,anMin,anMax])
//   let out=(ci.chPos/100)*(anMax-anMin)+anMin
//   out=Math.round(out*100)/100
//   
//   let sensor=+ch[getParamId(ccd,"air_temp_sensor")]// 0-4 analog temp
//   let temp=dbVals.z[ci.zInd][240][getParamId(ci.sn,`analogTemperature${sensor+1}`)]
  
  let anOut=(ci.chAnOut)?", "+ci.chAnOut.toPrecision(2)+"V":""
//   cl(sensor,temp)
  if(ci.chOverride==0){
    return `Analog Equipment: ${anOut}`
  }else{
    return `${eqOverrides[ci.chOverride]}, ${anOut}`
  }
}

var gesMZoneP=(ci)=>{
//   cl(ci)
  let ch=ci.ch
  let ccd=ci.ccd
  let sensor=+ch[getParamId(ccd,"air_temp_sensor")]// 0-4 analog temp
//   let temp=dbVals.z[ci.zInd][240][getParamId(ci.sn,`analogTemperature${sensor+1}`)]
  let temp=getDBV(ci.zInd,240,getParamId(ci.sn,`analogTemperature${sensor+1}`))
//   cl(sensor,temp)
  // cl(`${ci.chPos}%, ${temp} \u00B0F`)
  // cl(`${ci.chPos}%, ${eqOverrides[ci.chOverride]}`)
  if(ci.chOverride==0){
    return `${ci.chPos}%, ${temp} \u00B0F`
  }else{
    return `${ci.chPos}%, ${eqOverrides[ci.chOverride]}`
  }
}

var gesOnOff=(ci)=>{
//   cl(ci)
  let ch=ci.ch
  let ccd=ci.ccd
//   cl(ci)
//   cl(ci.chSwStatus)
  let sensor=+ch[getParamId(ccd,"air_temp_sensor")]||0// 0-4 analog temp
//   if(!sensor)
//   cl(ci.zInd)
//   cl(ci.sn)
//   cl(sensor)
//   cl(getParamId(ci.sn,`analogTemperature${sensor+1}`))
//   let temp=((dbVals.z[ci.zInd]||{})[240]||{})[getParamId(ci.sn,`analogTemperature${sensor+1}`)]
  let temp=getDBV(ci.zInd,240,getParamId(ci.sn,`analogTemperature${sensor+1}`))
//   cl(sensor,temp)
  if(ci.chOverride==0){
    return `${ci.chSwStatus} ${ci.chOverText}`
  }else{
    return `${ci.chSwStatus}  ${ci.chOverText}`
  }
}

var getDBV=(z,c,i)=>{return (((dbVals.z||{})[z]||{})[c]||{})[i]||0}

var gesCO2=(ci)=>{
  let ch=ci.ch
  let ccd=ci.ccd
//   let co2=(dbVals.z[ci.zInd][240]||{})[getParamId(ci.sn,`co2`)]
  let co2=getDBV(ci.zInd,240,getParamId(ci.sn,`co2`))
//   let sensor=+ch[getParamId(ccd,"air_temp_sensor")]// 0-4 analog temp
//   let temp=dbVals.z[ci.zInd][240][getParamId(ci.sn,`analogTemperature${sensor+1}`)]
  return `${(ci.chPos)?"On":"Off"}${ci.chOverText}, Currently: ${co2} PPM`
//   if(ci.chOverride==0){
//   }else{
//     return `${(ci.chRelay)?"On":"Off"}${ci.chOverText}, Currently: ${co2} PPM`
//   }
}

// pi[1800].snapshot_channels["lightDailyLightIntegral"] = 16
// pi[1800].snapshot_chan_Snapshot["accumulatedLight"] = 1


var gesHID=(ci)=>{
  let ch=ci.ch
  let ccd=ci.ccd
  let dli=ch[getParamId(ci.sc,`lightDailyLightIntegral`)]
  let un=lightUnit(ci.zInd).t + " Hr"
//   let acl=ch[getParamId(ci.scd,`accumulatedLight`)]
//   cl(dli,acl)
  if(ci.chOverride==0){// no override
    return `${(ci.chRelay)?"On":"Off"}, ${dli}${un}`
  }else{// if override
    return `${(ci.chRelay)?"On":"Off"}${ci.chOverText}`
  }
}

var gesNone=(ci)=>{
  let ch=ci.ch
  let ccd=ci.ccd
//   let dli=ch[getParamId(ci.sc,`lightDailyLightIntegral`)]
//   let un=lightUnit(ci.zInd).t + " Hr"
  return `${(ci.chRelay)?"On":"Off"}`
//   if(ci.chOverride==0){// no override
//   }else{// if override
//     return `${(ci.chRelay)?"On":"Off"}${ci.chOverText}`
//   }
}

var get800EquipmentStatus=(gwType,zInd,ind,ch)=>{
  let igCE="igCE"
  let igRE="igRE"
  let proport=[constant.EQ_800_VENT, constant.EQ_800_CURTAIN]
  let openCover=["Open","Closed","Covered","Uncovered"]
  let chType=+(ch[getParamId2(gwType,igCE,"channelType")]||0)
  let ocBase=(chType==constant.EQ_800_VENT)?0:2
  let stats={"40":"Humidify","41":"Dehumidify","42":"Cold Dehum","60":"Timed On",
    "61":"Timed Off","62":"Timed Position","80":"Forced On","81":"Forced Off",
    "82":"Force to Position","83":`Forced ${openCover[ocBase+0]}`,
    "84":`Forced ${openCover[ocBase+1]}`,
  }
  let chName=(ch[getParamId2(gwType,igCE,"channelName")]||0)
  let oneChOverride=+(ch[getParamId2(gwType,igCE,"oneChannelOverride")]||0)
  let twoChOverride=+(ch[getParamId2(gwType,igCE,"twoChannelOverrideMode")]||0)
  let twoChOverridePos=+(ch[getParamId2(gwType,igCE,"twoChannelOverridePosition")]||0)
  let status=+(ch[getParamId2(gwType,igRE,"status")]||0)
//   cl(status)
  let relay=+(ch[getParamId2(gwType,igRE,"relay")]||0)
  let position=+(ch[getParamId2(gwType,igRE,"position")]||0)
  let oneOver=+(ch[getParamId2(gwType,igRE,"oneOverride")]||0)
  let twoOver=+(ch[getParamId2(gwType,igRE,"twoOverride")]||0)
  var forceStatus
  if(proport.includes(chType)){
    let opts=["Auto",`Forced to Position ${twoChOverridePos}%`,`Forced ${openCover[ocBase+0]}`,
    `Forced ${openCover[ocBase+1]}`,"Forced Off"]
    if(chType==constant.EQ_800_VENT){}else{}
    forceStatus=opts[twoChOverride]
  }else{
    let opts=["Auto","Forced On","Forced Off"]
    forceStatus=opts[oneChOverride]
  }
  let rtdStatus=(stats[status])?`, (${stats[status]})`:""
//   cl(rtdStatus)

  return `${forceStatus}${rtdStatus}`
}

var getEquipmentStatus=(gwType,zInd,ind,ch,swPos,relState)=>{
  let infoStates=[
    "Auto Off","Auto On","Auto Off","Auto On",
    "Force Off","Force On","Force Off","Force On"]
// swPos, Pos, relay
// 0 0 0 auto off
// 0 0 1 auto on
// 0 1 0 auto off
// 0 1 1 auto on
// 1 0 0 Force off
// 1 0 1 Force On
// 1 1 0 Force Off
// 1 1 1 Force On

  if(gwType==800){return get800EquipmentStatus(gwType,zInd,ind,ch)}
  let sn="snapshots"
  let cc="configuration_channels"
  let ccd="configuration_channel_data"
  let sc="snapshot_channels"
//   let se="snapshot_ecphs"
  let scd="snapshot_channel_data"
  let chOverride=+(ch[getParamId(sc,"channelOverride")]||0)
//   cl(getParamId(sc,"channelOverride"))
  let chOverText=(chOverride)?`, ${eqOverrides[chOverride]}`:""
  let zone=dbVals.z[zInd]||{}
//   cl(gwType)
  let pos=+ch[getParamId(sc,"position")]
//   let relay=(gwType==1900)?pos!=0:+ch[getParamId(sc,"relay")]
  let relay=(gwType==1900)?relState:+ch[getParamId(sc,"relay")]
  var unitTime
  let swStatus=(relay)?"On":"Off"
  if(gwType==1900){
    unitTime=(zone[240]||{})[getParamId(sn,"currentTime")]// time local to controller
    // cl(unitTime)
    let da=new Date()
    let tzo=da.getTimezoneOffset()
    // cl(swPos,pos,relay)
    let infoIndex=
      ((swPos)?4:0)+
      ((pos)?2:0)+
      ((relay)?1:0)
//       cl(swPos,pos,relay,infoIndex)
    swStatus=infoStates[infoIndex]
//     swStatus=(swPos)?
//       (pos==relay)?
//         `Switch ${swStatus}`:
//         `Forced ${swStatus}`:
//       `Auto ${swStatus}`
//     cl(tzo)
    // unitTime-=60*tzo
  }else{
    unitTime=(zone[240]||{})[getParamId(sn,"unix_timestamp(unitTime)")]
  }
  let chInfo={
    zInd:zInd,
    cc:cc,
    ccd:ccd,
    sc:sc,
//     se:se,
    scd:scd,
    sn:sn,
    ch:ch,
    srvTime:(zone[240]||{})[getParamId(sn,"unix_timestamp(created)")],
    unitTime:unitTime,//(zone[240]||{})[getParamId(sn,"unix_timestamp(unitTime)")],
    chType:+ch[getParamId(cc,"channelType")],
    chDesc:ch[getParamId(cc,"userComment")],
    chUsed:+ch[getParamId(cc,"used")],
    chUserComment:+ch[getParamId(cc,"userComment")],
    chPos:pos,
    chRelay:relay,
    chSwPos:swPos,
    chRelState:relState,
    chSwStatus:swStatus,
    chOverride:chOverride,//+ch[getParamId(sc,"channelOverride")],
    chOverText:chOverText,
    chAnOut:+ch[getParamId(sc,"analogOutput")],
    ind:ind,
    ch:ch,
  }
//   cl(chInfo)
  if(chInfo.chPos==99){chInfo.chPos=100}
//   cl(chInfo)
  switch(chInfo.chType){
    case constant.EQ_IRR:
      return gesIrr(chInfo)
    case constant.EQ_PUMP:
      return gesPump(chInfo)
    case constant.EQ_FVALVE:
      return gesFValve(chInfo)
    case constant.EQ_VENT:
      return gesVent(chInfo)
    case constant.EQ_CURT:
      return gesCurtain(chInfo)
    case constant.EQ_MZONE:
      return gesMZone(chInfo)
    case constant.EQ_MIXV:
      return gesMixV(chInfo)
    case constant.EQ_MZONE2:
      return gesMZoneP(chInfo)
    case constant.EQ_ONOFF:
      return gesOnOff(chInfo)
    case constant.EQ_CO2:
      return gesCO2(chInfo)
    case constant.EQ_HID:
      return gesHID(chInfo)
    case constant.EQ_GEN_PID:
      return gesGenPid(chInfo)
    case constant.EQ_VAROUT:
      return gesVarOut(chInfo)
    case constant.EQ_NONE:
      return gesNone(chInfo)
    case constant.EQ_MIXV_AN:
      return gesAnMixV(chInfo)

    default:
   }
}


//                 if (eqOverride != EquipmentOverride.OVRTYPE_NONE ||
//                     channelSnapshotData["inWindow"] == null || !bool.TryParse((string)channelSnapshotData["inWindow"], out inWindow) ||
//                     channelSnapshotData["inQueue"] == null || !bool.TryParse((string)channelSnapshotData["inQueue"], out inQueue) ||
//                     channelSnapshotData["activeCount"] == null || !int.TryParse((string)channelSnapshotData["activeCount"], out activeCount) ||
//                     channelSnapshotData["offTime"] == null || !int.TryParse((string)channelSnapshotData["offTime"], out offTime) ||
//                     channelSnapshotData["accumulatedLight"] == null || !double.TryParse((string)channelSnapshotData["accumulatedLight"], out accumulatedLight) ||
//                     channelData["soil_moisture_threshold"] == null || !double.TryParse((string)channelData["soil_moisture_threshold"], out soilThreshold) ||
//                     channelData["on_duration"] == null || !int.TryParse((string)channelData["on_duration"], out onDuration) ||
//                     channelData["off_duration"] == null || !int.TryParse((string)channelData["off_duration"], out offDuration) ||
//                     channelData["soil_moisture_input"] == null || !int.TryParse((string)channelData["soil_moisture_input"], out soilInputSensor) ||
//                     channelData["irrigation_mode"] == null || !int.TryParse((string)channelData["irrigation_mode"], out irrMode) || !IrrigationEquipmentMode.IntValues.ContainsKey(irrMode)
//                     )
//                 {
export {getEquipmentStatus,
}
