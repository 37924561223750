import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Select01 from './C18Select01'
import C18SiteZoneSub00 from './C18SiteZoneSub00'
import C18Button00 from './C18Button00'
import {wsTrans} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo, loadSubscriptionInfo, getZoneIndex,loadSiteData,getZoneInfo, onCheckout,
  addToAdminLog, updateSubscription, getRecurlyAccount, getZonesCount, getZoneDiscount, getTotal, getLockedZones, getZoneCount,acctFeature,loadGatewaysInfo,getGatewayInfo} from './C18utils'
import {sendArray, dbVals, putZValue} from '../../components/utils/http';
import {cl, globs, getTime, allZones, allAddons, capitalize, round, zoneTypes, recurlySubdomain,getRandomString,getTimeI,groupColors,countNumbers,
  } from '../../components/utils/utils';
import history from "../../history"

class C18ManageZones extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      groups:[],
      siteSel:"",
      selGroup:"",
      siteMod:false,
      sortMode:0,
      loaded:false,
    }
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveZones)
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveGroups)
    this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
  }

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Groups", url:`/usa/c18/admin/manageGroups`},
            ]},
        },
      )
    }
  }

  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }

  loadInfo=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
//     cl(globs.zonesInfo)
//     let resp=await wsTrans("usa",{cmd: "cRest", uri: "/s/zoneGroups", method: "retrieve",
//       sessionId: globs.userData.session.sessionId, body: {}})
    this.allSiteGroups=globs.zonesInfo.groups
    // cl(this.allSiteGroups)
    this.setState({loaded:true})
//     this.state.loaded=true
//     cl("load info")
  }

  saveGroups=async()=>{
    let st=this.state
//     cl(st)
//     cl(st.groups)
//     cl(st.groups[0])
//     cl(st.zones)
    let uniqueGroups=!globs.usersInfo.groupInfo?.notUniqueGroups
    if(uniqueGroups){
      let groupInd=Object.assign({},st.groupInd)
      Object.keys(groupInd).forEach(k=>{groupInd[k].zones=[]})
      Object.keys(st.zones).forEach(k=>{
        let group=st.zones[k].group
        if(group){groupInd[group].zones.push(k)}
      })
    }
//     cl(groupInd)
    let query={
      siteId:st.siteSel,
      groups:st.groups,
    }
    let entry=this.allSiteGroups.filter(g=>{return g.siteId==st.siteSel})[0]
    if (entry) {
      entry.groups=st.groups
      await wsTrans("usa",{cmd: "cRest", uri: "/s/zoneGroups", method: "update",
        sessionId: globs.userData.session.sessionId, body: query})
        globs.events.publish("saveOK",true)
    }
  }

  assignColor=(groups)=>{
    let colors=groups.map(g=>{return g.color})
    for (let i=0;i<32;i++){
      if(!colors.includes(i)){return i}
    }
  }

  assignName=(groups)=>{
    let names=groups.map(g=>{return g.name})
    for(let i=0;i<countNumbers.length;i++){
      let name=`New Group ${countNumbers[i]}`
      if(!names.includes(name)){return name}
    }
  }

  addGroup=()=>{
    let st=this.state
    let groups=st.groups.slice(0)
    let groupInd=Object.assign({},st.groupInd)
    let group={
      name:this.assignName(groups),
      groupId:getRandomString(16),
      color:this.assignColor(groups),
      zones:[],
    }
    groups.push(group)
    groupInd[group.groupId]=group
//     cl(groups)
    globs.events.publish("savePageEnable",true)
    this.setState({groups:groups,groupInd:groupInd,siteMod:true,
      selGroup:group.groupId
    })
  }

  siteSel=async(vals)=>{
    let st=this.state
    if(st.siteMod){
      let res=await this.props.parms.getPopup({text:"Do you want to save your changes to this site's groups??",buttons:["No","Yes"]})
      if(res=="Yes"){
        let entry=this.allSiteGroups.filter(g=>{return g.siteId==st.siteSel})[0]
        if(!entry){
          entry={
            siteId:st.siteSel,
            groups:st.groups,
          }
          this.allSiteGroups.push(entry)
        }
        cl(this.allSiteGroups)
        cl(st.siteSel)
        entry.groups=st.groups
        await this.saveGroups()
      }
    }
//     cl(vals)
    let groups=this.allSiteGroups.filter(g=>{return g.siteId==vals.siteSel})[0]?.groups||[]
    cl(groups)
    let selGroup=groups[0]?.groupId
//     cl(groups)
    let zones={}
    globs.zonesInfo.info
      .filter(z=>{return z.siteId==vals.siteSel})
      .forEach(z=>{zones[z.zoneId]={name:z.zoneName,group:""}})
    let groupInd={}
    groups.forEach(g=>{
      groupInd[g.groupId]=g
//       cl(g)
      g.zones.forEach(z=>{
//         cl(z)
        if(zones[z]){zones[z].group=g.groupId}

      })
    })
//     cl(zones)
//     cl(groups)
    await this.setState({
      siteSel:vals.siteSel,
      groups:groups,
      groupInd:groupInd,
      selGroup:selGroup,
      zones:zones,
      zoneOrder:[],
      siteMod:false})
    this.sortZones({sortMode:1})
  }

//   deleteGroup=(groupId)=>{
//     cl(groupId)
//   }

  setZoneGroup=(vals)=>{
    let uniqueGroups=!globs.usersInfo.groupInfo?.notUniqueGroups
//     cl(uniqueGroups)
    let st=this.state
    if (st.selGroup) {
      if(uniqueGroups){
        let zones=Object.assign({},st.zones)
        //       cl(zones)
        //       cl(st)
        zones[vals.zoneId].group=(zones[vals.zoneId].group==st.selGroup)?"":st.selGroup
        //       cl(st.selGroup)
        this.setState({zones:zones})
        globs.events.publish("savePageEnable",true)
      }else{
        let groups=st.groups.slice(0)
        let group=(groups.filter(g=>{return g.groupId==st.selGroup})||[])[0]
        //       cl(groups)
        //       cl(group)
        if(group.zones.includes(vals.zoneId)){
          group.zones=group.zones.filter(z=>{return z!=vals.zoneId})
        }else{
          group.zones.push(vals.zoneId)
        }
        this.setState({groups:groups})
        globs.events.publish("savePageEnable",true)
      }
    }
  }

  sortZones=async(vals)=>{
    let st=this.state
//     cl(st)
    let sortMode=(Math.abs(vals.sortMode)==Math.abs(st.sortMode))?-1*st.sortMode:vals.sortMode
//     cl(sortMode)
    let zoneOrder=Object.keys(st.zones)

    let dir=(sortMode>0)?1:-1
//     let sortMode=Math.abs(vals.sortMode)
    var na,nb
    let sm2=Math.abs(sortMode)
    let uniqueGroups=!globs.usersInfo.groupInfo?.notUniqueGroups
    let selGroup=(st.groups.filter(g=>{return g.groupId==st.selGroup})||[])[0]
//     cl(sm2)
    zoneOrder.sort((a,b)=>{
      let za=st.zones[a]
      let zb=st.zones[b]
      switch(sm2){
        case 1:// by group name
          if(uniqueGroups){
            na=(st.groupInd[za.group]||{})?.name||"zzz"
            nb=(st.groupInd[zb.group]||{})?.name||"zzz"
          }else{
            na=selGroup?.zones.includes(a)?0:1
            nb=selGroup?.zones.includes(b)?0:1
          }
          if(na>nb){return dir}
          if(na<nb){return 0-dir}
          return 0
        case 2:
          na=za.name
          nb=zb.name
//           cl(na,nb)
          if(na>nb){return dir}
          if(na<nb){return 0-dir}
          return 0
      }
    })
//     cl(sortMode)
    await this.setState({sortMode:sortMode,zoneOrder:zoneOrder})
  }

  onChange=async(type,vals)=>{
    cl(type,vals)
    let st=this.state
    var groups,groupInd,selGroup,zones,group
    switch(type){
      case "addGroup":
        this.addGroup()
        break
      case "siteSel":
        this.siteSel(vals)
        break
      case "deleteGroup":
        globs.events.publish("savePageEnable",true)
        groups=st.groups.slice(0)
        groups=groups.filter(g=>{return g.groupId!=st.selGroup})
        selGroup=groups[0]?.groupId
        groupInd=Object.assign({},st.groupInd)
        delete groupInd[st.selGroup]
        zones=Object.assign({},st.zones)
        Object.keys(zones).forEach(k=>{
          let z=zones[k]
//           cl(z)
          if(z.group==st.selGroup){z.group=""}
        })
//         cl(zones)
        this.setState({groups:groups,groupInd:groupInd,selGroup:selGroup,
          zones:zones,siteMod:true})
        break
      case "groupName":
//         cl(vals)
        globs.events.publish("savePageEnable",true)
        groups=st.groups.slice(0)
        group=groups.filter(g=>{return g.groupId==st.selGroup})[0]
        group.name=vals.name
        this.setState({groups:groups})
        break
      case "zoneSelect":
        this.setZoneGroup(vals)
        break
      case "sortZones":
        this.sortZones(vals)
        break
      case "selGroup":
        if(st.selGroup==vals.selGroup){vals.selGroup=""}
        vals.sortMode=0
        let sortMode=st.sortMode
        await this.setState(vals)
        await this.sortZones({sortMode:sortMode})
        break
      default:
        this.setState(vals)
        break
    }
  }

  showGroupEdit=()=>{
//         disabled={!this.state.zoneAddName}
    let st=this.state
//     cl(st)
    let group=(st.groups.filter(g=>{return g.groupId==st.selGroup})[0])||{}
//     cl(group)
    return(
      <div>
        <label>Group Name</label>
        <C18Input00 type="text" className="with-right-button"
          value={group.name||""} disabled={!st.selGroup}
          onChange={e=>this.onChange("groupName",{name:e.currentTarget.value})}
        />
        <C18Button00 type="button" className="outlined" disabled={!st.selGroup}
          onClick={e=>this.onChange("deleteGroup",{groupId:group.groupId})}>Delete</C18Button00>
      </div>
    )
  }

  showGroupAdd=()=>{
    return(
      <div>
        <C18Button00 type="button" className="outlined"
          onClick={e=>this.onChange("addGroup")}>Add Group</C18Button00>
      </div>
    )
  }

  showGroupList=()=>{
    let st=this.state
    if(!st.siteSel){return}
    let rows=(st.groups||[]).map((g,i)=>{
//       cl(g)
      let color=groupColors[g.color]
      let rowColor=(g.groupId==st.selGroup)?"#EEEEEE":"white"
      return(
        <div key={i} style={{backgroundColor:rowColor,padding:8}}>
          <span style={{cursor:"pointer",backgroundColor:color,padding:5,borderRadius:5}}
            onClick={e=>this.onChange("selGroup",{selGroup:g.groupId})}
          >{g.name}<br/></span>
        </div>
      )
    })
    return(
      <div>
      {this.showGroupAdd()}
      <h4>Groups</h4>
      Select a group, and select the zones to be in it.
      <div className="clearfloat"/><br/>
        <div style={{
          width:500,
          height:300,
          padding:10,
          border:"1px solid",
          borderRadius:10,
          overflowY:"auto",
        }}>
        {rows}
        </div><br/>
        {this.showGroupEdit()}
      </div>
    )
  }

  showSitesOpts=()=>{
    return globs.sitesInfo.info.map((s,i)=>{
      return(
        <option key={i} value={s.siteId}>{s.name}</option>
      )
    })
  }

  showSelectSite=()=>{
    return(
      <div className="custom-select">
        <label htmlFor="">Select Site</label>

        <C18Select00 id=""
          parms={{list:true}}
          value={this.state.siteSel}
          onChange={e=>this.onChange("siteSel",{siteSel: e.currentTarget.value})}
        >
          {this.showSitesOpts()}
        </C18Select00>
        {false&&
          <span className="material-icons down-arrow">
            keyboard_arrow_down
          </span>
        }
      </div>
    )
  }

  showZoneList=()=>{
    let st=this.state
//     cl(st)
    if(!st.siteSel){return}
    let uniqueGroups=!globs.usersInfo.groupInfo?.notUniqueGroups
    let selGroup=(st.groups.filter(g=>{return g.groupId==st.selGroup})||[])[0]
//     cl(selGroup)
    let rows=st.zoneOrder.map((k,i)=>{
      var groupName,color
      let z=st.zones[k]
      if(uniqueGroups){
        let group=st.groupInd[z.group]||{}
        groupName=group.name||"No Group"
        color=groupColors[group.color]
//         cl(group)
//         cl(groupName,color)
      }else{
        let inSelGroup=selGroup?.zones.includes(k)
        groupName=(inSelGroup)?selGroup.name:""
        color=(inSelGroup)?groupColors[selGroup.color]:"white"
      }


      return(
        <tr key={i} style={{cursor:"pointer"}}
          onClick={e=>this.onChange("zoneSelect",{zoneId:k})}
        ><td
            height="30"
        ><span
          style={{backgroundColor:color,padding:5,
            borderRadius:5}}
        >{groupName}</span></td><td>{z.name}</td></tr>
      )
    })
    let icons=["",""]
    icons[Math.abs(st.sortMode)-1]=(st.sortMode>0)?"keyboard_arrow_down":"keyboard_arrow_up"
    return(
      <div>
      <h4>Zones</h4>
        <div style={{
          width:500,
          height:300,
          padding:10,
          border:"1px solid",
          borderRadius:10,
        }}>
        <table style={{width:"initial"}} cellSpacing="20"><tbody>
        <tr>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sortZones",{sortMode:1})}
        >Group <span className="material-icons-outlined">{icons[0]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sortZones",{sortMode:2})}
        >Zone <span className="material-icons-outlined">{icons[1]}</span></button></th>
        </tr>
          {rows}
        </tbody></table>
        </div><br/>
      </div>
    )

  }

  render(){
    let st=this.state
//     cl(st)
//     cl(globs)
    if(st.loaded){
      return(
        <div>
          {this.showSelectSite()}<br/>
          {this.showGroupList()}
          {this.showZoneList()}<br/>
        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}

export default C18ManageZones;
