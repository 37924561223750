import React from 'react';
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import config from '../../components/utils/config'
import { wsTrans } from '../utils/utils'
import { uploadTotal } from '../../components/utils/ws'
import { acctFeature, loadAccountInfo, loadZonesInfo, privs, getloadZonesInfo, getZoneInfo, getSiteInfo } from './C18utils'
import { cl, globs, constant } from '../../components/utils/utils';
import DayClearIcon from '../../visualization/icons/sun-icon.svg';
import NightClearIcon from '../../visualization/icons/moon-icon.svg';
import DayCloudIcon from '../../visualization/icons/day-cloudy-icon.svg';
import NightCloudIcon from '../../visualization/icons/night-cloudy-icon.svg';
import OvercastIcon from '../../visualization/icons/cloud-icon.svg';
import DaySnowIcon from '../../visualization/icons/day-cloud-snow-icon.svg';
import NightSnowIcon from '../../visualization/icons/night-cloud-snow-icon.svg';
import DayRainIcon from '../../visualization/icons/day-cloud-rain-icon.svg';
import NightRainIcon from '../../visualization/icons/night-cloud-rain-icon.svg';
import DayThunderstormIcon from '../../visualization/icons/day-cloud-rain-lightning-icon.svg';
import NightThunderstormIcon from '../../visualization/icons/night-cloud-rain-lightning-icon.svg';
import FunnelIcon from '../../visualization/icons/hurricane-icon.svg';
import { tempUnit } from '../utils/utils';
import {Link, withRouter } from 'react-router-dom'

class C18DateFormat00 extends React.Component {
  constructor(props) {
    super(props);
    // cl(props)
    this.state = {
      mode: "sites",
      value: (props.parms.value) ? "grid-select" : "list-select",
      weather: (props.parms.weather) ? true : false,
      syncPackInfo: "",
      formatted_address: "",
      isIconHovered: false,
      // isVisible:true,
    }
    //     this.showTimeZone=''
    //     cl("subscribe")
    //     cl(propss)
    if (props.parms.type == "quickWrench") {
      //       cl("subscribe")
      this.subscribe_syncPackInfo = globs.events.subscribe("syncPackInfo", this.syncPackInfo)
    }
    this.loadInfo()
  }
  handleMouseEnter = () => {
    this.setState({ isIconHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isIconHovered: false });
  };
  componentWillUnmount = () => {
    if (this.subscribe_syncPackInfo) { this.subscribe_syncPackInfo.remove() }
  }

  toggleVisibility = () => {
    if(this.props.parms?.onToggle)
      this.props.parms.onToggle();

    // this.setState({ isVisible: !this.state.isVisible })
  }

  syncPackInfo = (cmd) => {
//     cl(cmd)
    let spi=""
    switch(cmd.state){
      case "getConfig":
        spi=`Getting Config`
        break
      case "getCrc":
        spi=`Getting CRCs from Pearl`
        break
      case "getBadIds":
        spi=`Getting Bad Ids from Pearl`
        break
      case "getEquip":
        spi=`Getting Equipment Types from Pearl, ch: ${cmd.curPack}`
        break
      case "getPack":
        spi=`Synchronizing Packs: ${cmd.packsRem} to go, Current Pack: ${cmd.curPack}`
        break
      case "finding":
        spi=`Found ${cmd.packsRem} bad packs so far. . .`
        break
    }
    this.setState({syncPackInfo:spi})
  }

  listTileClick = (mode) => {
    var value
    let vals = {}
    switch (mode) {
      case "list-button":
      case "grid-button":
        value = mode.split("-")[0] + "-select"
        break
      case "phys-button":
        value = this.state.value// don't chnage tile / list mode
        vals.cmd = mode
        break
      case "godot-button":
        // redirect user to account/site selector
        let url = "https://c2dt.link4cloud.com/"
        if (this.props.parms.apiKey) {
          // cl(this.props.parms.apiKey)
          url = url + this.props.parms.apiKey
        }
        if (this.props.parms.siteId) {
          // cl(this.props.parms.siteId)
          url = url + `~${this.props.parms.siteId}`
        }
        if (this.props.parms.zoneId) {
          // cl(this.props.parms.zoneId)
          url = url + `~${this.props.parms.zoneId}`
        }
        // cl(url)
        window.open(url, "_blank", 'noopener,noreferrer')
        return
      default:
        break
    }
    this.setState({ value: value })
    vals[this.props.parms.valueId] = value
    //     cl(vals)
    this.props.parms.onChange(vals)
  }

  wrenchClick = (val, e) => {
    cl(val)
    e.preventDefault()
    this.props.parms.onChange(val)
  }

  loadInfo = async () => {
    if (this.state.weather) {
      let address
      let body = { endpoint: "forecast" }
      switch (this.props.parms.parent) {
        case "sites":
          Object.assign(body, { accountId: globs.userData.session.accountId, source: "sites" })
          if (globs.accountInfo.info.thoroughfare && globs.accountInfo.info.locality && globs.accountInfo.info.administrativeArea) {
            if (globs.accountInfo.info.coordinates && globs.accountInfo.info.formatted_address) {
              Object.assign(body, { coordinates: globs.accountInfo.info.coordinates })
            } else {
              address = `${globs.accountInfo.info.thoroughfare} ${globs.accountInfo.info.locality} ${globs.accountInfo.info.administrativeArea}`
              address = address.replaceAll(" ", "%20")
              cl(address)
              Object.assign(body, { address: address })
            }
            Object.assign(body, { formatted_address: globs.accountInfo.info.formatted_address })
          }
          break
        case "zones":
          if (this.props.parms.siteId) {
            let site = getSiteInfo(this.props.parms.siteId)
            // this.setState({siteId: site.siteId})
            if (site.thoroughfare && site.locality && site.administrativeArea) {
              Object.assign(body, { siteId: this.props.parms.siteId, source: "zones" })
              if (site.coordinates && site.formatted_address) {
                Object.assign(body, { coordinates: site.coordinates })
              } else {
                address = `${site.thoroughfare} ${site.locality} ${site.administrativeArea}`
                address = address.replaceAll(" ", "%20")
                Object.assign(body, { address: address })
              }
              Object.assign(body, { formatted_address: site.formatted_address })
            } else {
              Object.assign(body, { accountId: globs.userData.session.accountId, source: "sites" })
              if (globs.accountInfo.info.thoroughfare && globs.accountInfo.info.locality && globs.accountInfo.info.administrativeArea) {
                if (globs.accountInfo.info.coordinates && globs.accountInfo.info.formatted_address) {
                  Object.assign(body, { coordinates: globs.accountInfo.info.coordinates })
                } else {
                  address = `${globs.accountInfo.info.thoroughfare} ${globs.accountInfo.info.locality} ${globs.accountInfo.info.administrativeArea}`
                  address = address.replaceAll(" ", "%20")
                  Object.assign(body, { address: address })
                }
                Object.assign(body, { formatted_address: globs.accountInfo.info.formatted_address })
              }
            }
          }
          break
        case "dash":
          // REMOVE dash display for now
          // if (this.props.parms.siteId) {
          //   let site = getSiteInfo(this.props.parms.siteId)
          //   if (site.thoroughfare && site.locality && site.administrativeArea) {
          //     Object.assign(body, { siteId: this.props.parms.siteId })
          //     if (site.coordinates) {
          //       Object.assign(body, { coordinates: site.coordinates })
          //     } else {
          //       address = `${site.thoroughfare} ${site.locality} ${site.administrativeArea}`
          //       address = address.replaceAll(" ", "%20")
          //       Object.assign(body, { address: address })
          //     }
          //   } else {
          //     Object.assign(body, { accountId: globs.userData.session.accountId })
          //     if (globs.accountInfo.info.thoroughfare && globs.accountInfo.info.locality && globs.accountInfo.info.administrativeArea) {
          //       if (globs.accountInfo.info.coordinates) {
          //         Object.assign(body, { coordinates: globs.accountInfo.info.coordinates })
          //       } else {
          //         address = `${globs.accountInfo.info.thoroughfare} ${globs.accountInfo.info.locality} ${globs.accountInfo.info.administrativeArea}`
          //         address = address.replaceAll(" ", "%20")
          //         Object.assign(body, { address: address })
          //       }
          //     }
          //   }
          // }
          break
          case "default":
            break
      }
      if (body.coordinates || body.address) {
        let forecast = await wsTrans("usa", {
          cmd: "cRest", uri: "/s/weather", method: "retrieve",
          sessionId: globs.userData.session.sessionId, body: body
        })
        this.setState({ forecast: forecast.data?.properties, formatted_address: forecast.data?.formatted_address, matching_source: this.props.parms.parent === body.source})
        this.props.parms.onChange({ forecast: forecast.data?.properties })
      }
    }
  }

  findMatchingWord(sentence) {

    const wordList = [
      'sunny',
      'fair',
      'clear',
      'windy',
      'breezy',
      'clouds',
      'cloudy',
      'overcast',
      'snow',
      'ice',
      'rain',
      'drizzle',
      'showers',
      'thunderstorm',
      'pellets',
      'funnel',
      'tornado',
      'hurricane',
      'storm',];

    const lowercaseSentence = sentence.toLowerCase();
    for (let i = 0; i < wordList.length; i++) {
      if (lowercaseSentence.includes(wordList[i])) {
        return wordList[i];
      }
    }
    return wordList[0];
  }

  makeWeatherIconsEnum = (forecastedSentence, isDaytime) => {

    const sentence = forecastedSentence;
    const matchingWord = this.findMatchingWord(sentence);
    let forecastedIcon = [];

    if (isDaytime) {
      let icons = {
        "sunny": DayClearIcon,
        'fair': DayClearIcon,
        'clear': DayClearIcon,
        'windy': DayClearIcon,
        'breezy': DayClearIcon,
        'clouds': DayCloudIcon,
        'cloudy': DayCloudIcon,
        'overcast': OvercastIcon,
        'snow': DaySnowIcon,
        'ice': DaySnowIcon,
        'rain': DayRainIcon,
        'drizzle': DayRainIcon,
        'showers': DayRainIcon,
        'thunderstorm': DayThunderstormIcon,
        'pellets': DayThunderstormIcon,
        'funnel': FunnelIcon,
        'tornado': FunnelIcon,
        'hurricane': FunnelIcon,
        'storm': FunnelIcon,
      }
      forecastedIcon = icons[matchingWord]
    }

    else {
      let icons = {
        "sunny": NightClearIcon,
        'fair': NightClearIcon,
        'clear': NightClearIcon,
        'windy': NightClearIcon,
        'breezy': NightClearIcon,
        'clouds': NightCloudIcon,
        'cloudy': NightCloudIcon,
        'overcast': OvercastIcon,
        'snow': NightSnowIcon,
        'ice': NightSnowIcon,
        'rain': NightRainIcon,
        'drizzle': NightRainIcon,
        'showers': NightRainIcon,
        'thunderstorm': NightThunderstormIcon,
        'pellets': NightThunderstormIcon,
        'funnel': FunnelIcon,
        'tornado': FunnelIcon,
        'hurricane': FunnelIcon,
        'storm': FunnelIcon,
      }

      forecastedIcon = icons[matchingWord]
    }

    return forecastedIcon;
  }

  showListTile = () => {
    //     cl(this.state)
    var listSelect = "", gridSelect = "", physSelect = "", godotSelect = ""
    //     if(this.state.value){// value is "tileMode"
    //       gridSelect=" selected"
    //     }else{
    //       listSelect=" selected"
    //     }
    let selected = " selected"
    if (this.state.value == "list-select") { listSelect = selected }
    if (this.state.value == "grid-select") { gridSelect = selected }
    if (this.state.value == "phys-select") { physSelect = selected }
    if (this.state.value == "godotSelect") { godotSelect = selected }

    return (
      <div className="section-controls">
        {acctFeature("godotView") &&
          <C18Button00 type="button" className={`phys-button material-icons${godotSelect}`} aria-pressed="false"
            onClick={() => { this.listTileClick("godot-button") }}>3d_rotation</C18Button00>
        }
        <C18Button00 type="button" className={`grid-button material-icons${gridSelect}`} aria-label="grid"
          aria-pressed="true" title="Grid View" onClick={() => { this.listTileClick("grid-button") }}>apps</C18Button00>
        <C18Button00 type="button" className={`list-button material-icons${listSelect}`} aria-pressed="false"
          title="List View" onClick={() => { this.listTileClick("list-button") }}>view_list</C18Button00>
        {/* Commenting out / Removing this feature for Now as per C20C22-1922 */}
        {false && acctFeature("physView") &&
          <C18Button00 type="button" className={`phys-button material-icons${physSelect}`} aria-pressed="false"
            onClick={() => { this.listTileClick("phys-button") }}></C18Button00>
        }
        
      </div>
    )
  }

  showWrench = () => {
    //     cl("show")
    return (
      <div id="/zone/zoneEquipment/equipmentSettings" className="section-controls">
        <C18Anchor00 id="/zone/zoneSensors/stages" to="" className="quick-charts" aria-label="quick-charts"
          onClick={e => this.wrenchClick({ wrenchCmd: "stages" }, e)}>
          <span className="material-icons-outlined">
            show_chart
          </span>
          <span className="quick-charts">Stages</span>
        </C18Anchor00>
        <C18Anchor00 to=""
          className="settings-button material-icons-outlined flip"
          aria-label={this.props.parms.ariaLabel}
          style={{ position: "relative", zIndex: 5 }}
          onClick={e => this.wrenchClick({ wrenchCmd: "wrench" }, e)}>settings</C18Anchor00>
      </div>
    )
  }

  getUploadColor = () => {
    var uploadColor
    //     cl(uploadTotal)
    if (uploadTotal > 15) {
      if (uploadTotal > 30) {
        uploadColor = "#FF8888"
      } else {
        uploadColor = "#FFCC88"
      }
    } else {
      if (uploadTotal > 3) {
        uploadColor = "#FFFF88"
      } else {
        uploadColor = "#88FF88"
      }
    }
    return uploadColor
  }

  showQuickWrench = () => {
    let isSuper = false
    if (privs("super", "", constant.AREA_PRIVS_READ) || (
      (globs.privsInfo.thirdPartyAccounts?.length > 1))) {
      isSuper = true
    }
    //       isSuper=false
    //       cl(isSuper)
    //     cl("show")
    return (
      <div className="section-controls">
        <C18Anchor00 id="/zone/zoneSensors/quickCharts" to="" className="quick-charts" aria-label="quick-charts"
          onClick={e => this.wrenchClick({ wrenchCmd: "charts" }, e)}>
          <span className="material-icons-outlined">
            signal_cellular_alt
          </span>
          <span className="quick-charts">Quick Charts</span>
        </C18Anchor00>
        {acctFeature("godotView") &&
          <C18Button00 type="button" className={`phys-button material-icons`} aria-pressed="false"
            onClick={() => { this.listTileClick("godot-button") }}>3d_rotation</C18Button00>
        }
        <C18Anchor00 id="/zone/zoneSensors/sensorSettings" to=""
          className="settings-button material-icons-outlined flip"
          aria-label={this.props.parms.ariaLabel}
          style={{ position: "relative", zIndex: 5 }}
          onClick={e => this.wrenchClick({ wrenchCmd: "wrench" }, e)}>settings</C18Anchor00>
        {(([800,1900].includes(this.props.parms.gatewayType)) && isSuper) &&// only on Pearl
          <>
            <C18Anchor00 to="" className="settings-button material-icons-outlined flip"
              aria-label={this.props.parms.ariaLabel}
              title="Force full upload of this Zone"
              style={{ backgroundColor: this.getUploadColor() }}
              onClick={e => this.props.parms.onChange({ e: e, wrenchCmd: "upload" }, e)}>
              upload</C18Anchor00>
            <C18Anchor00 to="" className="settings-button material-icons-outlined flip"
              aria-label={this.props.parms.ariaLabel}
              title="Delete all zone data"
              style={{ backgroundColor: "#FFCCCC" }}
              onClick={e => this.props.parms.onChange({ e: e, wrenchCmd: "eraseDB" }, e)}>
              delete</C18Anchor00>
          </>
        }
      </div>
    )
  }

  showRightElement = () => {
    //     cl(this.props.parms)
    switch (this.props.parms.type) {
      case "listTile":
        return this.showListTile()
      case "quickWrench":
        return this.showQuickWrench()
      case "wrench":
        return this.showWrench()
      default:
        return null
    }
  }

  showForecast = () => {
    if (this.state.forecast) {
      const { isIconHovered } = this.state;
      const locationIconStyle = {
        backgroundColor: isIconHovered ? 'rgba(0, 0, 0, 0.1)' : 'transparent', // Change to desired hover color
        transition: 'background-color 0.3s ease',
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'none',
      };
      let units = {
        "degF": String.fromCharCode(176) + "F",
        "degC": String.fromCharCode(176) + "C",
        "percent": "%",
      }

      let si = getSiteInfo(this.props.parms.site)
      //     cl(si)
      let weatherStation = si?.weatherStation || this.props.parms.zone
      //     cl(weatherStation)
      let zi = getZoneInfo(weatherStation)
      //     cl(zi)
      let wsz = (zi || {}).siteZoneIndex || 0
      let outTempUnits = tempUnit(wsz).t


      let curr = this.state.forecast.periods[0] // today forecast
      let finaltemperature;
      if (outTempUnits === "°" + curr.temperatureUnit) {
        finaltemperature = `${curr.temperature}\u00B0${curr.temperatureUnit}`
      }
      else {
        if (outTempUnits === '°F') {
          let temp = (curr.temperatureUnit * 9 / 5) + 32;
          finaltemperature = `${temp.toFixed(1)}${outTempUnits}`
        }
        else {
          let temp = (curr.temperatureUnit - 32) * 5 / 9;
          finaltemperature = `${temp.toFixed(1)}${outTempUnits}`

        }
      }

      // let name = curr.name
      let temp = finaltemperature
      let short_forecast = curr.shortForecast
      // let detailed_forecast = curr.detailedForecast
      // let wind_dir = curr.windDirection
      // let wind_spd = curr.windSpeed
      // let pop = `${curr.probabilityOfPrecipitation.value} ${units[curr.probabilityOfPrecipitation.unitCode.split(":")[1]]}`
      // let dewpoint = `${curr.dewpoint.value} ${units[curr.dewpoint.unitCode.split(":")[1]]}`
      // let rh = `${curr.relativeHumidity.value} ${units[curr.relativeHumidity.unitCode.split(":")[1]]}`

      let icon = this.makeWeatherIconsEnum(curr.shortForecast || "", curr.isDaytime)

      return (
        <span style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
          <img src={icon} width="30px" style={{ marginRight: "5px" }} />
          <span style={{ marginRight: "10px", color: "#393D46" }}>{temp}</span>
          <span className='hide-mobile' style={{ marginRight: "10px", color: "#393D46" }}>({short_forecast})</span>
          <span className="material-icons" title={this.state.formatted_address} style={{ marginLeft: "1px" }}>
            <Link
              to={{
                pathname: (this.props.parms.parent === "sites") ? "/usa/c18/admin/manageAccount2" : "/usa/c18/admin/manageSites2",
                state: { from: this.props.location.pathname }
              }}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              >
                <span
                  className="material-icons"
                  style={locationIconStyle}>
                  {(this.state.matching_source && this.state.formatted_address) ? "location_on" : "wrong_location"}
                </span>
            </Link>
          </span>
        </span>
        )
    }
  }

  showSyncPacks = () => {
    let st = this.state
    if (st.syncPackInfo) {
      return (
        <div className="section-header" style={{ paddingLeft: 50, backgroundColor: "#EEEEEE", display: "flex", width: "100%" }}>
          {st.syncPackInfo}
        </div>
      )
    }
  }

  showDateFormat = () => {
    let pa = this.props.parms
//     cl(pa)
    //     cl(this.props.parms)

    //    if (this.props.parms.timeZone) {
    //     this.showTimeZone = <div style={{
    //       float: "left",
    //       lineHeight: "40px",
    //       fontSize: "16px",
    //       fontWeight: "500",
    //       paddingLeft: "10px"
    //     }}>({this.props.parms.timeZone})</div>
    //    }
    //     let tz=this.props.parms.timeZone
    let tzPart = (pa.timeZone) ? ` (${pa.timeZone})` : ""
    let dateShow = `${pa.leftString}${tzPart}`
    //         {this.showTimeZone}
    return (
      <>
        <div
          className="section-header"
          style={{ display: 'flex', width: '100%' }}
        >
          <div
            className={this.props.parms.leftClass}
            id={`date-${pa.parent}`}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {dateShow}
            {this.props.parms.type === 'wrench' ? (
              <i
                onClick={() => this.toggleVisibility()}
                style={{ marginRight: '2px', marginLeft: '2px', cursor: 'pointer' }}
                className="material-icons"
              >
                {this.props.parms.value ? 'visibility_off' : 'visibility'}
                
              </i>
            ) : (
              ''
            )}
            <span
              className="material-icons-outlined not-connected-icon"
              title="Zone is Offline"
            >
              report_problem
            </span>
            {this.showForecast()}
          </div>
          <div style={{ flex: 1, marginLeft: '10px', minWidth: "fit-content"}}>
            {this.showRightElement()}
          </div>
        </div>
        {this.showSyncPacks()}
      </>
    );
  }


  render() {
    return this.showDateFormat()
  }
}

export default withRouter(C18DateFormat00);
