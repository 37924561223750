import React from 'react';
import C18MuiInput from '../../usa/components/C18MuiInput'
import ReactHtmlParser from 'html-react-parser'
import {cl, globs} from '../../components/utils/utils';

class TextGeneral extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      passwordVisible: false,
      password: "",
      passwordAllowed: false,
    }
    this.updateFui()
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
    this.conflictRef=React.createRef()
  }

  updateFui=()=>{
    if(!this.changed){
//       cl(this.props.ind)
      this.props.getValue(this.props.ind, {type: "scalar"})
    }
  }
  
  savePageEvent=()=>{
    this.changed=false
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.checkPassword(this.props.value)
    }
  }

  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
  }

  checkPassword=(password)=>{
//     globs.events.publish("savePageEnable",false)
    let regex = /^([A-Z]|\d)([a-z]|\d)*\s?(([A-Z]|\d)([a-z]|\d)*)?/g
    let result = regex.test(password)
    if(result && password.length > 8 && password.length < 12 && this.changed){
      globs.events.publish("savePageEnable",true)
    }
    this.setState({passwordAllowed: result && password.length > 8 && password.length < 12, password: password})
  }

  trimZeros=(val)=>{
    var pos
    if((pos=val.indexOf("\u0000"))>=0){val=val.substring(0,pos)}
//     cl(val.length)
    return val
  }

  onChange=(vals, type=null)=>{
    this.changed=true
    switch (type) {
      case "eyeClick":
        this.setState({passwordVisible: !this.state.passwordVisible})
        break
      case "password":
        this.checkPassword(vals.target.value)
        this.props.onChange(this.props.ind, {value: vals.target.value})
        break
      default:
        this.props.onChange(this.props.ind,
          {value: this.trimZeros(vals.target.value)})
        break
    }
  }

  render(){
//     cl(this.props);
//     cl(this.props.value)
    // className="floatleft"
    let pr=this.props
    let bgColor="white"
    return(
      <div id={this.props?.custom?.infoId} style={{zIndex:10}}>
        <label>{this.props.title}</label>
        <div style={{
          width: "215px",
          position: "relative",
          marginBottom:16,
        }}>
          {this.props?.custom?.password ?
            <input type={(this.state.passwordVisible ? "text" : "password")} ref={this.conflictRef}
            style={{backgroundColor:bgColor}}
            title={pr.rest.parent({uri:"pid",pr:pr})}
            id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
            value={this.props.value}
            onChange={(e)=>this.onChange(e, "password")}
            maxLength={this.props.custom.maxlength}
            minLength={8}
            />
          :
            <input type="text" ref={this.conflictRef}
            style={{backgroundColor:bgColor}}
            title={pr.rest.parent({uri:"pid",pr:pr})}
            id={`{"cmd":"watch","type":"${pr.type}","id":${pr.cont.pid},"zuci":"${pr.zuci}"}`}
            value={this.props.value}
            onChange={this.onChange}
            maxLength={this.props.custom.maxlength}
            />
          }
          {this.props?.custom?.password&&
          <div style={{
            position: "absolute",
            top: "28%",
            right: "4%"
          }}
          onClick={e=>this.onChange(e, "eyeClick")}
          >
           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
             <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
             <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
           </svg>
          </div>
          }
        </div>
        {this.props?.custom?.password&&!this.state.passwordAllowed&&
        <span style={{color: 'red'}}>This password is not allowed</span>}
      </div>
    );
  }
}

  export default TextGeneral ;
